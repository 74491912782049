









































































































import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History, // @ts-ignore TODO : Typescriptiong.
} from "tiptap-vuetify";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { WebDescription } from "@/store/modules/courses/interfaces/Webdescription.interface";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { NeedToFillPanel } from "./NewCourseWebStep.vue";

export default defineComponent({
  name: "Textfields",
  components: { TiptapVuetify },
  props: {
    value: {
      type: Object,
      required: true,
    },
    activePanel: { type: Number },
    panelLabels: {
      type: Array as PropType<NeedToFillPanel[]>,
      required: true,
    },
    isCourseDone: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const webDescription = useAutoDeepCloneProp<WebDescription>(props, "value");

    const emitChanges = () => {
      emit("input", deepCloneObject(webDescription.value));
    };

    return {
      webDescription,
      emitChanges,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
    };
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
});
