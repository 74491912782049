





























































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseStepperDynamic",
  props: {
    selectedSteps: {
      type: Array,
      required: true,
    },
    dataCy: { type: String, required: false },
    conditionalSteps: {
      type: Array,
      required: false,
    },
    postBtn: {
      type: String,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    disablePostButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    draftBtn: {
      type: String,
      required: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  }, // base form stepper dynamic? TODO
  data() {
    return {
      currentStep: 1,
    };
  },
  computed: {
    availableSteps(): any {
      return this.selectedSteps
        .map((s: any, idx: any) => {
          s.slotName = this.getSlotName(idx);
          return s;
        })
        .filter((s: any) => s.isAvailable);
    },
    steps() {
      return this.availableSteps.length;
    },
  },
  methods: {
    async nextStep() {
      const currentStepComponent = this.availableSteps[this.currentStep - 1];
      if ((await currentStepComponent.validate?.()) ?? true) {
        const nextStep = Math.max((this.currentStep + 1) % (this.steps + 1), 1);
        const nextStepComponent = this.availableSteps[nextStep - 1];
        requestAnimationFrame(() => {
          nextStepComponent.onStepEnter?.();
        });
        (currentStepComponent.onNext ?? ((nf: any) => nf()))(() => (this.currentStep = nextStep));
        this.$emit("nextStep");
      }
    },
    previouStep() {
      if (this.currentStep > 0) {
        const nextStepComponent = this.availableSteps[this.currentStep - 1];
        nextStepComponent.onStepGoBack?.();
        this.currentStep--;
      }
    },
    getSlotName(index: number) {
      return Object.keys(this.$scopedSlots)[index];
    },
    cancelStepper() {
      this.$emit("cancel");
    },
    async handleValidation(emitEvent?: string) {
      const currentStepComponent = this.availableSteps[this.currentStep - 1];
      if ((await currentStepComponent.validate?.()) ?? true) {
        emitEvent && this.$emit(emitEvent);
      }
    },
    postAction() {
      this.handleValidation("post");
    },
    draftAction() {
      this.handleValidation("draft");
    },
  },
});
