










































import { api } from "@/api/api";
import { ApiScheduleBaseDtoValidationResponseDto, ApiUpsertPlanDtoType } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { PlanState } from "./schedule.types";
import { validateNotEmpty, validateTimeInput } from "@/shared/helpers/validationHelpers";
import ScheduleForm from "./ScheduleForm.vue";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { getTimeOfDay, setDateHourAndMin } from "@/shared/helpers/dateHelpers";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "NewCoursePlanStep",
  components: {
    ScheduleForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const courseValues = useAutoDeepCloneProp<CourseDto>(props, "value");
    const isPlanValid = ref(false);
    const startTime = computed(() => getTimeOfDay(new Date(courseValues.value.startDate ?? new Date())));
    const endTime = computed(() => getTimeOfDay(new Date(courseValues.value.endDate ?? new Date())));

    const validate = async () => {
      const isTimeValid = getValidatableRef(refs.timeForm)?.validate();
      if (!isTimeValid) {
        openNotification(store, NotificationItemType.Error, "Start-/slutt-klokkeslett må fylles ut");
        return false;
      }

      if (!courseValues.value.plan?.schedules?.length) {
        openNotification(store, NotificationItemType.Information, "Fortsetter kursopprettelse uten timeplan");
        emit("input", deepCloneObject(courseValues.value));
        return true;
      }

      // Frontend/form validation
      if (!isPlanValid.value) {
        openNotification(
          store,
          NotificationItemType.Error,
          "Alle valideringsfeil i timeplanen må rettes opp før du kan gå videre"
        );
        return false;
      }

      if (courseValues.value.plan.schoolRouteId) {
        // Backend validation based on school route (if specified)
        try {
          await api.schedule.validateSchedulesAsync(courseValues.value.plan);
        } catch (e: unknown) {
          if (!isAxiosErrorResponse(e) || !isValidationErrorResponse(e.response.data)) {
            throw e;
          }
          const { generalValidationErrors, validationList } = e.response.data;
          generalValidationErrors?.forEach((message) => {
            openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${message}`);
          });
          validationList?.forEach((item) => {
            item.validationErrors?.forEach((message) => {
              openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${message}`);
            });
          });
          return false;
        }
      }

      emit("input", deepCloneObject(courseValues.value));
      return true;
    };

    const handleUpdatePlan = ({ plan, validation }: PlanState) => {
      isPlanValid.value = Object.values(validation).every((valid) => valid);

      const coursePlan: ApiUpsertPlanDtoType = {
        schoolRouteId: courseValues.value.plan?.schoolRouteId ?? 0,
        registerHours: courseValues.value.plan?.registerHours ?? false,
        schedules: courseValues.value.plan?.schedules ?? [],
      };
      const updatedPlan: ApiUpsertPlanDtoType = { ...coursePlan, ...plan };

      courseValues.value.plan = updatedPlan;
    };

    const minHours = computed(() => +(store.state.courses.curriculum.hoursMin ?? 0));
    const maxHours = computed(() => +(store.state.courses.curriculum.hoursMax ?? 0));
    const hoursWithInstructor = computed(() => +courseValues.value.hoursWithInstructor);
    const setStartTime = (time: string) => {
      courseValues.value.startDate = setDateHourAndMin(
        new Date(courseValues.value.startDate ?? new Date()),
        time
      ).toISOString();
      emit("input", deepCloneObject(courseValues.value));
    };
    const setEndTime = (time: string) => {
      courseValues.value.endDate = setDateHourAndMin(
        new Date(courseValues.value.endDate ?? new Date()),
        time
      ).toISOString();
      emit("input", deepCloneObject(courseValues.value));
    };

    return {
      courseValues,
      startTime,
      endTime,
      validate,
      handleUpdatePlan,
      minHours,
      maxHours,
      hoursWithInstructor,
      validateNotEmpty,
      validateTimeInput,
      setStartTime,
      setEndTime,
      isCourseDone: props.value.status === CourseStatus.Closed,
    };
  },
});

const isAxiosErrorResponse = (error: unknown): error is { response: { data: unknown } } =>
  typeof error === "object" && error !== null && "response" in error;

const isValidationErrorResponse = (response: unknown): response is ApiScheduleBaseDtoValidationResponseDto =>
  typeof response === "object" &&
  response !== null &&
  ("validationList" in response || "generalValidationErrors" in response);
