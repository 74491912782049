

























































import { ApiCreateCourseDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, Ref } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "NewCourseTestStep",
  components: { FormElement, BaseTooltipIconButton },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    // TODO switch ApiCreateCourseDto to CourseDto
    const courseValues: Ref<ApiCreateCourseDto> = useAutoDeepCloneProp<ApiCreateCourseDto>(props, "value");
    let hasCreatedInitial = false;

    const addTestEntry = () => {
      if (!courseValues.value.tests) {
        return;
      }
      courseValues.value.tests.push({
        testTypeId: 0,
        name: "",
        description: "",
      });
    };

    const deleteTestEntry = (index: number) => {
      if (!courseValues.value.tests) {
        return;
      }
      courseValues.value.tests.splice(index, 1);
    };

    const testTypes = computed(() => store.state.testTypes.types);

    runStoreAction(store, StoreModules.TestTypes, StoreActions.TestTypesActions.FetchTypes);

    const onStepEnter = () => {
      if (hasCreatedInitial || !courseValues.value.tests || courseValues.value.tests.length > 0) {
        return;
      }
      hasCreatedInitial = true;
      addTestEntry();
    };

    const onStepGoBack = () => {
      // need to emit to save for when going back
      context.emit("input", deepCloneObject(courseValues.value));
    };

    const validate = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (isValid) {
        context.emit("input", deepCloneObject(courseValues.value));
        return true;
      }
      return false;
    };

    return {
      testTypes,
      validateNotEmpty,
      courseValues,
      addTestEntry,
      deleteTestEntry,
      validate,
      onStepEnter,
      onStepGoBack,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
    };
  },
});
