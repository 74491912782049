












































































import { api } from "@/api/api";
import { ApiUpsertCourseDescriptionDto, ApiGetApplicationFormTemplateDto } from "@/api/generated/Api";
import Textfields from "@/components/course/new/steps/web/Textfields.vue";
import WebInfoModal from "@/components/course/new/steps/web/WebInfoModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { CourseEnrollmentStatus } from "@/shared/enums/CourseEnrollmentStatus.enum";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ParticipantEnrollmentStatus } from "@/shared/enums/ParticipantEnrollmentStatus.enum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty, validateTimeInput } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import Vue from "vue";

export interface NeedToFillPanel {
  propName: keyof ApiUpsertCourseDescriptionDto;
  label: string;
}

export default defineComponent({
  name: "NewCourseWebStep",
  components: { Textfields, BaseDatePicker, BaseModal, WebInfoModal },
  props: {
    value: {
      type: Object as PropType<CourseDto>,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const courseValues = useAutoDeepCloneProp<CourseDto>(props, "value");
    const webApplications = ref<ApiGetApplicationFormTemplateDto[]>();
    const showInfoModal = ref(false);
    const activePanel = ref(0);

    const onStepGoBack = () => {
      // only emit on go to next or prev step
      context.emit("input", deepCloneObject(courseValues.value));
    };

    const vocationalSchool = computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId));

    const needToFillPanel: NeedToFillPanel[] = [
      { propName: "intro", label: "Ingress" },
      { propName: "targetGroup", label: "Målgruppe" },
      {
        propName: "foreknowledgeRequirements",
        label: vocationalSchool.value ? "Opptakskrav" : "Forkunnskaper",
      },
      { propName: "learningObjectives", label: "Læringsmål" },
      { propName: "courseContent", label: "Faginnhold" },
      { propName: "method", label: "Gjennomføring" },
      { propName: "financing", label: "Finansiering" },
      { propName: "exam", label: "Eksamen" },
    ];

    const enrollmentCourseOptions = vocationalSchool.value
      ? [CourseEnrollmentStatus.Open]
      : [CourseEnrollmentStatus.Open, CourseEnrollmentStatus.Full];
    const enrollmentStudentOptions = vocationalSchool.value
      ? [ParticipantEnrollmentStatus.Applied]
      : [ParticipantEnrollmentStatus.Applied, ParticipantEnrollmentStatus.Enrolled];

    const validate = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (!isValid) {
        return false;
      }
      const somePanelsInvalid = needToFillPanel.some((current, index) => {
        if (courseValues.value.webDescription) {
          if (
            courseValues.value.webDescription[current.propName] === "" ||
            courseValues.value.webDescription[current.propName] === "<p></p>"
          ) {
            openNotification(store, NotificationItemType.Warning, `${current.label} må fylles inn `);
            activePanel.value = index;
            return true;
          }
          return false;
        }
      });
      if (somePanelsInvalid) {
        return false;
      }

      context.emit("input", deepCloneObject(courseValues.value));
      return true;
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        webApplications.value = (await api.appformtemplate.getAppFormTemplatesAsync()).data.filter(
          (current) => current.isActive
        );
      });
    });

    return {
      enrollmentCourseOptions,
      enrollmentStudentOptions,
      courseValues,
      validateNotEmpty,
      validateTimeInput,
      activePanel,
      validate,
      showInfoModal,
      isVocationalSchool: vocationalSchool,
      courseCategories: computed(() => store.state.courses.courseCategories),
      webApplications,
      onStepGoBack,
      needToFillPanel,
      isCourseDone: props.value.status === CourseStatus.Closed,
    };
  },
});
