





















import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { getTimeOfDay, setDateHourAndMin } from "@/shared/helpers/dateHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CourseDates",
  components: { BaseDatePicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const courseValues = useAutoDeepCloneProp<CourseDto>(props, "value");

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    const setStartDate = (date: string) => {
      courseValues.value.startDate = setDateHourAndMin(
        new Date(date ?? new Date()),
        getTimeOfDay(courseValues.value.startDate ? new Date(courseValues.value.startDate) : new Date())
      ).toISOString();
      emitChanges();
    };

    const setEndDate = (date: string) => {
      courseValues.value.endDate = setDateHourAndMin(
        new Date(date ?? new Date()),
        getTimeOfDay(courseValues.value.endDate ? new Date(courseValues.value.endDate) : new Date())
      ).toISOString();
      emitChanges();
    };

    return {
      courseValues,
      validateNotEmpty,
      setStartDate,
      setEndDate,
    };
  },
});
