










































































import { api } from "@/api/api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateBoolean, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { getCourseInitialState } from "@/store/modules/courses/course.initial-state";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref, Ref, PropType } from "@vue/composition-api";
import { Curriculum } from "@/store/modules/plans/interfaces/Curriculum.interface";

interface SelectCustomer {
  id: number;
  name: string;
}

export default defineComponent({
  name: "CourseRegistrationInfo",
  components: { FormElement, BaseDatePicker },

  props: {
    value: {
      type: Object,
      required: true,
    },
    curriculum: {
      type: Object as PropType<Curriculum>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");
    const customerOrganizations = ref<SelectCustomer[]>();
    const initialWebDescription = getCourseInitialState(
      store.state.hoc.usePreFilledValues,
      props.curriculum
    ).webDescription;

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    // temp fix as isOrderable is flipped (true = not on web) Will fix in later BE change
    const updateIsOrderable = (onWeb: boolean) => {
      courseValues.value.isOrderable = !onWeb;

      // remove web step if values are not valid (backend will fail if they are)
      const templateIdOrPublishDateNotSet =
        courseValues.value.webDescription?.templateId === "" || courseValues.value.webDescription?.publishDate === "";
      if (!onWeb && templateIdOrPublishDateNotSet) {
        delete courseValues.value.webDescription;
      }
      if (onWeb && !("webDescription" in courseValues.value)) {
        courseValues.value.webDescription = initialWebDescription;
      }

      emitChanges();
    };

    onMounted(async () => {
      const response = await api.customer.getCustomerOrganizationsAsync();
      customerOrganizations.value = response.data.map((customer) => ({
        id: customer.id,
        name: customer.customer.name,
      }));
    });

    return {
      courseValues,
      validateNotEmpty,
      validateBoolean,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      courseDefaultOptions: computed(() => store.state.courses.courseDefaultOptions),
      executionPlan: computed(() => store.state.execution.executionPlan),
      customerOrganizations,
      emitChanges,
      updateIsOrderable,
      currentDate: new Date().toISOString().slice(0, 10),
    };
  },
});
