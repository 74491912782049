



















































































import { api } from "@/api/api";
import { ApiGetCurrentEmployeeDto, ApiGetDepartmentDto, ApiIdNameDtoType } from "@/api/generated/Api";
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { CourseCreationType } from "@/shared/enums/courseCreationType.enum";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateBoolean, validateNotEmpty, validateOrganization } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, ref, Ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseInfo",
  components: { FormElement },
  props: {
    value: {
      type: Object as PropType<CourseDto>,
      required: true,
    },
    courseMode: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");
    const curriculum = computed(() => store.state.courses.curriculum);
    const organizerOrganizations = ref<ApiIdNameDtoType[]>();
    const organizationDepartments = ref<ApiGetDepartmentDto[]>();
    const activeUser = ref<ApiGetCurrentEmployeeDto>();

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    const getOrganizerOrganizations = async () =>
      (await api.organization.getPotentialOrganizerOrganizations({ isOrganizer: true })).data?.sort(
        localeSortByProperty("name")
      );

    const getOrganizationDepartmens = async () => (await api.organization.getDepartmentsAsync()).data;
    const getUser = async () => (await api.user.getCurrentEmployeeAsync()).data;

    onMounted(async () => {
      const [organizations, departments, user] = await Promise.all([
        getOrganizerOrganizations(),
        getOrganizationDepartmens(),
        getUser(),
      ]);
      organizerOrganizations.value = organizations.filter((org) => org.isActive);
      organizationDepartments.value = departments.filter((org) => org.isActive);
      activeUser.value = user;
      emitChanges();
    });

    const courseDefaultOptions = computed(() => store.state.courses.courseDefaultOptions);
    const courseDefaultStatuses = computed(() =>
      courseValues.value.status !== "avsluttet" ? courseDefaultOptions.value.courseStatuses : ["avsluttet"]
    );

    return {
      courseValues,
      curriculum,
      validateNotEmpty,
      validateOrganization,
      activeUser,
      validateBoolean,
      isCourseEdit: props.courseMode === CourseCreationType.Edit,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      courseDefaultOptions,
      courseDefaultStatuses,
      executionPlan: computed(() => store.state.execution.executionPlan),
      emitChanges,
      organizerOrganizations,
    };
  },
});
