













































































import { api } from "@/api/api";
import { ApiArticleDtoType, ApiCourseEconomyBaseDataDto } from "@/api/generated/Api";
import Files from "@/components/course/new/steps/economy/Files.vue";
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification, runStoreAction } from "@/shared/helpers/store.helpers";
import { validateNotEmpty, validateKasId } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { Organization } from "@/store/modules/memberOrganizations.store/interfaces/Organization.interface";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import Vue from "vue";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "NewCourseEconomyStep",

  components: { FormElement, Files },
  props: {
    curriculumId: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const availableArticles = ref<ApiArticleDtoType[]>([]);
    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");
    const economyDefaultOptions = ref<ApiCourseEconomyBaseDataDto>({});

    const departmentId = ref(1);

    const onStepEnter = () => loadAvailableArticles(props.curriculumId);
    onMounted(() => loadAvailableArticles(props.curriculumId));

    const loadAvailableArticles = (curriculumId: number) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const [curriculumResponse, economyDefaultOptionsResponse, employeeSelfResponse] = await Promise.all([
          api.curriculum.getCurriculumAsync(curriculumId),
          api.economy.getCourseEconomyBaseAsync(),
          api.user.getCurrentEmployeeAsync(),
        ]);

        if (!curriculumResponse.data.teachingMethod || !economyDefaultOptionsResponse.data.articles) {
          openNotification(store, NotificationItemType.Warning, "Det skjedde en feil under henting av artikler");
          return;
        }

        economyDefaultOptions.value = economyDefaultOptionsResponse.data;
        availableArticles.value = economyDefaultOptionsResponse.data.articles.filter(
          (article) => article.teachingMethod === curriculumResponse.data.teachingMethod
        );
        departmentId.value = employeeSelfResponse.data.currentOrganizationId;
      });
    };

    runStoreAction(
      store,
      StoreModules.MemberOrganizations,
      StoreActions.MemberOrganizationsActions.FetchMemberOrganizations
    );

    const validate = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (isValid) {
        context.emit("input", deepCloneObject(courseValues.value));
        return true;
      }
      return false;
    };

    return {
      courseValues,
      departmentId,
      validateNotEmpty,
      validateKasId,
      validate,
      onStepEnter,
      economyDefaultOptions,
      availableArticles,
      organizationList: computed<Organization[]>(() => store.state.memberOrganizations.organizations),
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      isCourseDone: props.value.status === CourseStatus.Closed,
    };
  },
});
