





























import CourseCoordinators from "@/components/course/new/steps/info/CourseCoordinators.vue";
import CourseDates from "@/components/course/new/steps/info/CourseDates.vue";
import CourseInfo from "@/components/course/new/steps/info/CourseInfo.vue";
import CourseLocationInfo from "@/components/course/new/steps/info/CourseLocationInfo.vue";
import CourseRegistrationInfo from "@/components/course/new/steps/info/CourseRegistrationInfo.vue";
import CourseTeachingHours from "@/components/course/new/steps/info/CourseTeachingHours.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { Curriculum } from "@/store/modules/plans/interfaces/Curriculum.interface";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, Ref, PropType } from "@vue/composition-api";
import Vue from "vue";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "NewCoursInfoStep",
  components: {
    CourseInfo,
    CourseLocationInfo,
    CourseDates,
    CourseTeachingHours,
    CourseCoordinators,
    CourseRegistrationInfo,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    courseMode: {
      type: String,
      required: true,
    },
    curriculum: {
      type: Object as PropType<Curriculum>,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");

    runStoreAction(store, StoreModules.Courses, StoreActions.CoursesActions.FetchCourseDefaultOptions);
    runStoreAction(store, StoreModules.Contacts, StoreActions.ContactsActions.FetchContacts);
    runStoreAction(
      store,
      StoreModules.MemberOrganizations,
      StoreActions.MemberOrganizationsActions.FetchMemberDepartment
    );

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    const validate = () => {
      const isValid = (refs.form as Vue & { validate: () => boolean }).validate();
      if (isValid) {
        emitChanges();
        return true;
      }
      return false;
    };

    return {
      courseValues,
      isCourseDone: props.value.status === CourseStatus.Closed,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      validate,
      emitChanges,
    };
  },
});
