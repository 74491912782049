























import { ApiCreateCourseDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
import EmployeeSearchInput from "@/components/course/new/steps/info/EmployeeSearchInput.vue";

export default defineComponent({
  name: "CourseCoordinators",
  components: { EmployeeSearchInput },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();

    const courseValues = useAutoDeepCloneProp<CourseDto>(props, "value");

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    const selectEmployee =
      (key: Extract<keyof ApiCreateCourseDto, "courseSupervisorId" | "lecturerId" | "contactId">) =>
      (employeeId: number) => {
        courseValues.value[key] = employeeId;
        emitChanges();
      };

    return {
      emitChanges,
      courseValues,
      selectCourseSupervisor: selectEmployee("courseSupervisorId"),
      selectLecturer: selectEmployee("lecturerId"),
      selectContact: selectEmployee("contactId"),
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
    };
  },
});
